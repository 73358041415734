import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '221, 200, 126',
		'primary-dark': '38, 50, 56',
		'accent': '0, 119, 255',
		'accent-plus': '255, 255, 255',
	},
});
